<template>
  <div class="p-col-12">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div class="card p-shadow-8">
      <header class="jumbotron">
        <h3>
          <strong>{{ currentUser.username }}</strong> Perfil
          <Button
            v-if="!inEditing"
            icon="pi pi-pencil"
            class="p-button-rounded"
            @click="inEditing = true"
          />
        </h3>
      </header>
      <form
        name="taskForm"
        class="p-col-12 p-md-12"
        @submit.prevent="submitServiceForm"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-5">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-12 p-mt-2">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="name"
                    v-model="currentUser.name"
                    :disabled="true"
                  />
                  <label for="inputtext">Nome</label>
                </span>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-4">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="number"
                    v-model="currentUser.technicianInitials"
                    :disabled="true"
                  />
                  <label for="inputtext">Número de Colaborador</label>
                </span>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-8">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="number"
                    v-model="currentUser.email"
                    :disabled="true"
                  />
                  <label for="inputtext">Email Profissional</label>
                </span>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="number"
                    v-model="currentUser.department"
                    :disabled="true"
                  />
                  <label for="inputtext">Departamento</label>
                </span>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="number"
                    v-model="currentUser.area"
                    :disabled="true"
                  />
                  <label for="inputtext">Área</label>
                </span>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <v-date-picker
                  v-model="userInfo.admissionDate"
                  is-required
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                  :disabled="true"
                >
                  <template v-slot="{ inputValue }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('admissionDate') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        :disabled="true"
                      />
                      <label for="inputtext">Data de Admissão</label>
                    </span>
                  </template>
                </v-date-picker>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <v-date-picker
                  v-model="userInfo.exitDate"
                  is-required
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('endContract') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        :disabled="true"
                      />
                      <label for="inputtext">Data de Fim de Contrato</label>
                    </span>
                  </template>
                </v-date-picker>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <v-date-picker
                  v-model="userInfo.exitDate"
                  is-required
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('exitDate') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        :disabled="true"
                      />
                      <label for="inputtext">Data de Saída</label>
                    </span>
                  </template>
                </v-date-picker>
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <v-date-picker
                  v-model="userInfo.birthDate"
                  v-validate="'required'"
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('birthDate') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="
                          !inEditing || (inEditing && userInfo.birthDate)
                            ? null
                            : inputEvents
                        "
                        :disabled="
                          !inEditing || (inEditing && userInfo.birthDate)
                        "
                        v-validate="'required'"
                        name="birthDate"
                      />
                      <label for="inputtext">Data de Nascimento</label>
                    </span>
                  </template>
                </v-date-picker>
                <small
                  v-if="errors.has('birthDate')"
                  class="p-error"
                  role="alert"
                  >Data de Nascimento é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <Dropdown
                    name="namaritalStatus"
                    v-model="userInfo.namaritalStatus"
                    :options="namaritalStatusList"
                    optionLabel="status"
                    optionValue="status"
                    v-bind:class="[
                      { 'p-error': errors.has('birthDate') },
                      'form-control',
                    ]"
                    :disabled="
                      !inEditing ||
                      (inEditing &&
                        (userInfo.namaritalStatus != null ||
                          userInfo.namaritalStatus != ''))
                    "
                    v-validate="'required'"
                  />
                  <label for="inputtext">Estado Civil</label>
                </span>
                <small
                  v-if="errors.has('namaritalStatus')"
                  class="p-error"
                  role="alert"
                  >Estado Civil é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-12">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="addess"
                    v-bind:class="[
                      { 'p-error': errors.has('addess') },
                      'form-control',
                    ]"
                    v-model="userInfo.addess"
                    :disabled="!inEditing"
                    maxlength="55"
                    v-validate="'required'"
                  />
                  <label for="inputtext">Morada</label>
                </span>
                <small v-if="errors.has('addess')" class="p-error" role="alert"
                  >Morada é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-3 p-md-3">
                <span class="p-float-label">
                  <InputMask
                    name="zipCode"
                    mask="9999-999"
                    v-model="userInfo.zipCode"
                    slotChar="____-___"
                    v-bind:class="[
                      { 'p-error': errors.has('zipCode') },
                      'form-control',
                    ]"
                    :disabled="!inEditing"
                    v-validate="'required'"
                  />
                  <label for="inputtext">Código Postal</label>
                </span>
                <small v-if="errors.has('zipCode')" class="p-error" role="alert"
                  >Código Postal é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-9 p-md-9">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="local"
                    v-bind:class="[
                      { 'p-error': errors.has('local') },
                      'form-control',
                    ]"
                    v-model="userInfo.local"
                    :disabled="!inEditing"
                    v-validate="'required'"
                  />
                  <label for="inputtext">Localidade</label>
                </span>
                <small v-if="errors.has('local')" class="p-error" role="alert"
                  >Localidade é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-3">
                <span class="p-float-label">
                  <InputMask
                    name="telephone"
                    mask="999999999"
                    v-model="userInfo.telephone"
                    v-bind:class="[
                      { 'p-error': errors.has('telephone') },
                      'form-control',
                    ]"
                    :disabled="!inEditing || (inEditing && userInfo.telephone)"
                    v-validate="'required'"
                  />
                  <label for="inputtext">Telemóvel</label>
                </span>
                <small
                  v-if="errors.has('telephone')"
                  class="p-error"
                  role="alert"
                  >Telemóvel é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-9">
                <span class="p-float-label">
                  <InputText
                    type="email"
                    name="personalEmail"
                    v-model="userInfo.personalEmail"
                    v-bind:class="[
                      { 'p-error': errors.has('personalEmail') },
                      'form-control',
                    ]"
                    :disabled="
                      !inEditing || (inEditing && userInfo.personalEmail)
                    "
                    v-validate="'required'"
                  />
                  <label for="inputtext">Email</label>
                </span>
                <small
                  v-if="errors.has('personalEmail')"
                  class="p-error"
                  role="alert"
                  >Email é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <InputText
                    type="text"
                    name="citizenCardNum"
                    v-bind:class="[
                      { 'p-error': errors.has('citizenCardNum') },
                      'form-control',
                    ]"
                    v-model="userInfo.citizenCardNum"
                    :disabled="
                      !inEditing || (inEditing && userInfo.citizenCardNum)
                    "
                    v-validate="'required'"
                  />
                  <label for="inputtext">Nº do Cartão do Cidadão</label>
                </span>
                <small
                  v-if="errors.has('citizenCardNum')"
                  class="p-error"
                  role="alert"
                  >Número do Cartão do Cidadão é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <v-date-picker
                  v-model="userInfo.citizenCardDate"
                  :min-date="new Date()"
                  is-required
                  :masks="{
                    input: 'DD-MM-YYYY',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          { 'p-error': errors.has('citizenCardDate') },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        :disabled="
                          (inEditing &&
                            validateCCDate(userInfo.citizenCardDate)) ||
                          !inEditing
                        "
                        name="citizenCardDate"
                        v-validate="'required'"
                      />
                      <label for="inputtext"
                        >Validade do Cartão do Cidadão</label
                      >
                    </span>
                  </template>
                </v-date-picker>
                <small
                  v-if="errors.has('citizenCardDate')"
                  class="p-error"
                  role="alert"
                  >Cartão do Cidadão Validade é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <InputText
                    name="nif"
                    mask="999999999"
                    v-model="userInfo.nif"
                    v-bind:class="[
                      { 'p-error': errors.has('nif') },
                      'form-control',
                    ]"
                    :disabled="!inEditing || (inEditing && userInfo.nif)"
                    v-validate="'required'"
                  />
                  <label for="inputtext">Nº Identificação Fiscal (NIF)</label>
                </span>
                <small v-if="errors.has('nif')" class="p-error" role="alert"
                  >NIF é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <InputText
                    name="niss"
                    mask="99999999999"
                    v-model="userInfo.niss"
                    v-bind:class="[
                      { 'p-error': errors.has('niss') },
                      'form-control',
                    ]"
                    :disabled="!inEditing || (inEditing && userInfo.niss)"
                    v-validate="'required'"
                  />
                  <label for="inputtext">Nº Segurança Social (NISS)</label>
                </span>
                <small v-if="errors.has('niss')" class="p-error" role="alert"
                  >NISS é obrigatório</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <InputMask
                    name="holders"
                    mask="9"
                    v-model="userInfo.holders"
                    v-bind:class="[
                      { 'p-error': errors.has('holders') },
                      'form-control',
                    ]"
                    :disabled="!inEditing"
                    v-validate="'required'"
                  />
                  <label for="inputtext">IRS - Nº de Titulares</label>
                </span>
                <small v-if="errors.has('holders')" class="p-error" role="alert"
                  >IRS - Nº de Titulares</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-6">
                <span class="p-float-label">
                  <InputMask
                    name="irsDependents"
                    mask="9"
                    v-model="userInfo.irsDependents"
                    v-bind:class="[
                      { 'p-error': errors.has('irsDependents') },
                      'form-control',
                    ]"
                    :disabled="!inEditing"
                    v-validate="'required'"
                  />
                  <label for="inputtext">IRS - Nº de dependentes</label>
                </span>
                <small
                  v-if="errors.has('irsDependents')"
                  class="p-error"
                  role="alert"
                  >IRS - Nº de dependentes</small
                >
              </div>

              <div class="p-mt-3 p-field p-col-12 p-md-12">
                <span class="p-float-label">
                  <InputText
                    name="iban"
                    v-model="userInfo.iban"
                    v-bind:class="[
                      { 'p-error': errors.has('iban') },
                      'form-control',
                    ]"
                    :disabled="!inEditing"
                    v-validate="'required'"
                  />
                  <label for="inputtext">IBAN</label>
                </span>
                <small v-if="errors.has('iban')" class="p-error" role="alert"
                  >IBAN é obrigatório</small
                >
              </div>
              <div class="p-mt-3 p-field p-col-12 p-md-12">
                <span class="p-float-label">
                  <InputText
                    name="function"
                    v-model="userInfo.function"
                    v-bind:class="[
                      { 'p-error': errors.has('function') },
                      'form-control',
                    ]"
                    :disabled="'true'"
                  />
                  <label for="inputtext">Função</label>
                </span>
                <small
                  v-if="errors.has('function')"
                  class="p-error"
                  role="alert"
                  >Função é obrigatório</small
                >
              </div>
              <Button
                v-if="userInfo.function_description_url != ''"
                label="Ver descrição da função"
                icon="pi pi-eye"
                class="p-button-primary p-ml-1"
                @click="viewFunctionDescription"
              />
            </div>
          </div>

          <div class="p-field p-col-12 p-md-7">
            <ProfileLiteraryAbilities
              v-bind:userLiteraryAbilities="userLiteraryAbilities"
              v-bind:inEditing="false"
              v-bind:removed="removed.literaryAbilities"
            />
            <Divider />
            <ProfileAdditionalTraining
              v-bind:userAdditionalTraining="userAdditionalTraining"
              v-bind:inEditing="inEditing"
              v-bind:removed="removed.additionalTraining"
            />
            <Divider />
            <ProfileProfessionalExperience
              v-bind:userProfessionalExperience="userProfessionalExperience"
              v-bind:inEditing="inEditing"
              v-bind:removed="removed.professionalExperience"
            />
            <Divider />
            <ProfileDocs
              v-bind:userprofileDocs="userprofileDocs"
              v-bind:inEditing="inEditing"
              v-bind:hasCar="this.userInfo.hasCar"
            />
          </div>
        </div>
        <Button
          v-if="inEditing"
          label="Gravar"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          v-if="inEditing"
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger p-ml-1"
          @click="cancel"
        />
      </form>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import { getOnlyDate, isDateBeforeToday } from "../helpers/helpers";
import profileLiteraryAbilities from "./ProfileLiteraryAbilities";
import profileAdditionalTraining from "./ProfileAdditionalTraining";
import profileProfessionalExperience from "./ProfileProfessionalExperience";
import profileDocs from "./ProfileDocs";
export default {
  name: "Profile",
  components: {
    ProfileLiteraryAbilities: profileLiteraryAbilities,
    ProfileAdditionalTraining: profileAdditionalTraining,
    ProfileProfessionalExperience: profileProfessionalExperience,
    ProfileDocs: profileDocs,
  },
  data() {
    return {
      loading: false,
      inEditing: false,
      namaritalStatusList: [
        { status: "Solteiro(a)" },
        { status: "Casado(a)" },
        { status: "Divorciado(a)" },
        { status: "Viúvo(a)" },
      ],
      userInfo: {},
      userLiteraryAbilities: [],
      userAdditionalTraining: [],
      userProfessionalExperience: [],
      userprofileDocs: {
        citizenCard: { id: null, name: null, url: null },
        cv: { id: null, name: null, url: null },
        qualificationsCertificate: { id: null, name: null, url: null },
        carDeclaration: { id: null, name: null, url: null },
      },
      removed: {
        literaryAbilities: [],
        additionalTraining: [],
        professionalExperience: [],
      },
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
  created() {
    this.getUserAllInfo();
  },
  methods: {
    viewFunctionDescription() {
      window.open(this.userInfo.function_description_url, "_blank");
    },
    getUserAllInfo() {
      return userService
        .getUserAllInfo(this.currentUser.id)
        .then((response) => {
          this.removed.literaryAbilities = [];
          this.removed.additionalTraining = [];
          this.removed.professionalExperience = [];
          this.userLiteraryAbilities = response.literaryAbilities;
          this.userAdditionalTraining = response.additionalTraining;
          this.userProfessionalExperience = response.professionalExperience;
          this.userprofileDocs = response.documents;
          this.userInfo = response;
          return;
        });
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }

        this.$validator.pause();
        this.$validator.reset();

        let formData = new FormData();
        formData.append(
          "namarital_status",
          this.userInfo.namaritalStatus != undefined
            ? this.userInfo.namaritalStatus
            : ""
        );
        formData.append(
          "addess",
          this.userInfo.addess != undefined ? this.userInfo.addess : ""
        );
        formData.append(
          "zip_code",
          this.userInfo.zipCode != undefined ? this.userInfo.zipCode : ""
        );
        formData.append(
          "local",
          this.userInfo.local != undefined ? this.userInfo.local : ""
        );
        formData.append(
          "telephone",
          this.userInfo.telephone != undefined ? this.userInfo.telephone : ""
        );
        formData.append(
          "personal_email",
          this.userInfo.personalEmail != undefined
            ? this.userInfo.personalEmail
            : ""
        );
        formData.append(
          "citizen_card_num",
          this.userInfo.citizenCardNum != undefined
            ? this.userInfo.citizenCardNum
            : ""
        );
        formData.append(
          "nif",
          this.userInfo.nif != undefined ? this.userInfo.nif : ""
        );
        formData.append(
          "niss",
          this.userInfo.niss != undefined ? this.userInfo.niss : ""
        );
        formData.append(
          "holders",
          this.userInfo.holders != undefined ? this.userInfo.holders : ""
        );
        formData.append(
          "irs_dependents",
          this.userInfo.irsDependents != undefined
            ? this.userInfo.irsDependents
            : ""
        );
        formData.append(
          "iban",
          this.userInfo.iban != undefined ? this.userInfo.iban : ""
        );

        if (this.userInfo.exitDate != undefined) {
          if (this.userInfo.exitDate instanceof Date) {
            formData.append("exit_date", getOnlyDate(this.userInfo.exitDate));
          } else {
            formData.append("exit_date", this.userInfo.exitDate);
          }
        }

        if (this.userInfo.admissionDate != undefined) {
          if (this.userInfo.admissionDate instanceof Date) {
            formData.append(
              "admission_date",
              getOnlyDate(this.userInfo.admissionDate)
            );
          } else {
            formData.append("admission_date", this.userInfo.admissionDate);
          }
        }

        if (this.userInfo.birthDate != undefined) {
          if (this.userInfo.birthDate instanceof Date) {
            formData.append("birth_date", getOnlyDate(this.userInfo.birthDate));
          } else {
            formData.append("birth_date", this.userInfo.birthDate);
          }
        } else {
          formData.append("birth_date", "");
        }

        if (this.userInfo.citizenCardDate != undefined) {
          if (this.userInfo.citizenCardDate instanceof Date) {
            formData.append(
              "citizen_card_date",
              getOnlyDate(this.userInfo.citizenCardDate)
            );
          } else {
            formData.append("citizen_card_date", this.userInfo.citizenCardDate);
          }
        } else {
          formData.append("citizen_card_date", "");
        }

        this.userLiteraryAbilities.forEach((element, index) => {
          formData.append(`literaryAbilities[${index}][id]`, element.id);
          formData.append(
            `literaryAbilities[${index}][course]`,
            element.course
          );
          formData.append(
            `literaryAbilities[${index}][duration]`,
            element.duration
          );
          formData.append(
            `literaryAbilities[${index}][entity]`,
            element.entity
          );
          formData.append(`literaryAbilities[${index}][year]`, element.year);
          if (element.edited != undefined) {
            formData.append(
              `literaryAbilities[${index}][edited]`,
              element.edited
            );
          }
          if (element.new != undefined) {
            formData.append(`literaryAbilities[${index}][new]`, element.new);
          }
          if (element.file != undefined) {
            formData.append(
              `literaryAbilities[${index}][file_${element.id}]`,
              element.file
            );
          }
        });

        this.userLiteraryAbilities.forEach((element, index) => {
          formData.append(`literaryAbilities[${index}][id]`, element.id);
          formData.append(
            `literaryAbilities[${index}][course]`,
            element.course
          );
          formData.append(
            `literaryAbilities[${index}][duration]`,
            element.duration
          );
          formData.append(
            `literaryAbilities[${index}][entity]`,
            element.entity
          );
          formData.append(`literaryAbilities[${index}][year]`, element.year);
          if (element.edited != undefined) {
            formData.append(
              `literaryAbilities[${index}][edited]`,
              element.edited
            );
          }
          if (element.new != undefined) {
            formData.append(`literaryAbilities[${index}][new]`, element.new);
          }
          if (element.file != undefined) {
            formData.append(
              `literaryAbilities[${index}][file_${element.id}]`,
              element.file
            );
          }
        });

        this.userAdditionalTraining.forEach((element, index) => {
          formData.append(`additionalTraining[${index}][id]`, element.id);
          formData.append(
            `additionalTraining[${index}][course]`,
            element.course
          );
          formData.append(
            `additionalTraining[${index}][duration]`,
            element.duration
          );
          formData.append(
            `additionalTraining[${index}][entity]`,
            element.entity
          );
          formData.append(`additionalTraining[${index}][year]`, element.year);
          if (element.edited != undefined) {
            formData.append(
              `additionalTraining[${index}][edited]`,
              element.edited
            );
          }
          if (element.new != undefined) {
            formData.append(`additionalTraining[${index}][new]`, element.new);
          }
          if (element.file != undefined) {
            formData.append(
              `additionalTraining[${index}][file_${element.id}]`,
              element.file
            );
          }
        });

        this.userProfessionalExperience.forEach((element, index) => {
          formData.append(`professionalExperience[${index}][id]`, element.id);
          formData.append(
            `professionalExperience[${index}][period]`,
            element.period
          );
          formData.append(
            `professionalExperience[${index}][entity]`,
            element.entity
          );
          formData.append(
            `professionalExperience[${index}][activity]`,
            element.activity
          );
          if (element.edited != undefined) {
            formData.append(
              `professionalExperience[${index}][edited]`,
              element.edited
            );
          }
          if (element.new != undefined) {
            formData.append(
              `professionalExperience[${index}][new]`,
              element.new
            );
          }
        });

        this.removed.literaryAbilities.forEach((element, index) => {
          formData.append(`removed[literaryAbilities][${index}]`, element.id);
        });
        this.removed.additionalTraining.forEach((element, index) => {
          formData.append(`removed[additionalTraining][${index}]`, element.id);
        });
        this.removed.professionalExperience.forEach((element, index) => {
          formData.append(
            `removed[professionalExperience][${index}]`,
            element.id
          );
        });

        if (this.userprofileDocs.citizenCard.file != undefined) {
          formData.append(
            `documents[citizenCard][citizenCardFile]`,
            this.userprofileDocs.citizenCard.file
          );
        }
        if (this.userprofileDocs.cv.file != undefined) {
          formData.append(
            `documents[cv][cvFile]`,
            this.userprofileDocs.cv.file
          );
        }
        if (this.userprofileDocs.qualificationsCertificate.file != undefined) {
          formData.append(
            `documents[qualificationsCertificate][qualificationCertificateFile]`,
            this.userprofileDocs.qualificationsCertificate.file
          );
        }
        if (this.userprofileDocs.carDeclaration.file != undefined) {
          formData.append(
            `documents[carDeclaration][carDeclarationFile]`,
            this.userprofileDocs.carDeclaration.file
          );
        }

        userService
          .updateUserAllInfo(this.currentUser.id, formData)
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao gravar",
                life: 3000,
              });
            }
            this.inEditing = false;
            this.getUserAllInfo();
            return this.$toast.add({
              severity: "success",
              summary: "Perfil Alterado",
              detail: "Perfil alterado com sucesso",
              life: 3000,
            });
          });
      });
    },
    cancel() {
      this.inEditing = false;
      return this.getUserAllInfo();
    },
    validateCCDate(date) {
      if (!date) {
        return false;
      }
      if (isDateBeforeToday(getOnlyDate(date))) {
        return false;
      }
      return true;
    },
  },
};
</script>
